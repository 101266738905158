import React, { forwardRef, useImperativeHandle, useState } from 'react'
import classNames from 'classnames'

import * as styles from './NavDrawer.module.scss'

const NavDrawer = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    toggleDrawer,
  }))
  const [state, setState] = useState({ closed: true })

  const toggleDrawer = (close) => {
    setState({ closed: close || !state.closed })
  }

  const navDrawerClasses = classNames(styles.navDrawer, {
    [styles.closed]: state.closed,
  })

  return (
    <div className={navDrawerClasses}>
      <div className={styles.buttonContainer}>
        <button
          aria-label="Toggle Drawer"
          className={styles.close}
          onClick={toggleDrawer}
        />
      </div>
      {props.navItems}
    </div>
  )
})

export default NavDrawer
