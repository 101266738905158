import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import { useLocation } from '@reach/router';

const Seo = ({
  description = '',
  lang = 'en',
  meta = [],
  title,
  image,
  keywords = []
}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
            og {
              siteName
              twitterCreator
            }
          }
        }
      }
    `
  );

  const pageTitle = title
    ? `${title} | Miriam Chernick`
    : site?.siteMetadata?.title;

  const defaultTitle = site.siteMetadata?.title;

  const ogImage = '/card.png';
  const siteDescription = description || site?.siteMetadata?.description;

  // Use the location hook to get current page URL
  const location = useLocation();

  const metas = [
    {
      name: `image`,
      content: image || ogImage
    },
    {
      name: 'description',
      content: siteDescription
    },
    {
      name: 'og:image:width',
      content: image ? '1200' : '1600'
    },
    {
      name: 'og:image:height',
      content: image ? '630' : '838'
    },
    {
      name: 'og:type',
      content: 'website'
    },
    {
      name: 'og:title',
      content: pageTitle
    },
    {
      name: 'og:description',
      content: siteDescription
    },
    {
      name: 'og:site_name',
      content: site?.siteMetadata?.og?.siteName
    },
    {
      name: 'og:url',
      content: `${site?.siteMetadata?.siteUrl}${location.pathname}`
    },
    {
      name: 'twitter:card',
      content: 'summary_large_image'
    },
    {
      name: 'twitter:description',
      content: siteDescription
    },
    {
      name: 'twitter:title',
      content: pageTitle
    },
    {
      name: 'twitter:creator',
      content: site?.siteMetadata?.og?.twitterCreator
    }
  ].concat(meta);

  // If we have keywords, then add it
  if (keywords) {
    metas.push({
      name: 'keywords',
      content: keywords
    });
  }

  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      defaultTitle={defaultTitle}
      titleTemplate={`%s | ${defaultTitle}`}
    >
      {metas.map((meta) => (
        <meta key={meta.name} name={meta.name} content={meta.content} />
      ))}
      {title && <title>{title}</title>}
    </Helmet>
  );
};

export default Seo;
