// extracted by mini-css-extract-plugin
export var active = "navigation-module--active--5964e";
export var container = "navigation-module--container--a923d";
export var hamburger = "navigation-module--hamburger--419f7";
export var logo = "navigation-module--logo--2a40f";
export var logoLink = "navigation-module--logoLink--7a832";
export var navDecor = "navigation-module--navDecor--92748";
export var navWrapper = "navigation-module--navWrapper--3acc8";
export var navigation = "navigation-module--navigation--72143";
export var navigationItem = "navigation-module--navigationItem--6d9a8";
export var pigeon = "navigation-module--pigeon--bc8dc";
export var scrolled = "navigation-module--scrolled--e672c";