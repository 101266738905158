import React from 'react';
import classNames from 'classnames';
import { useLocation } from '@reach/router';

import * as styles from './footer.module.css';

import IgIcon from '../images/Insta.svg';
import TwIcon from '../images/Twitter.svg';

const Footer = () => {
  const location = useLocation();
  const isHome = location.pathname === '/';

  return (
    <footer
      className={classNames(styles.container, { [styles.fixed]: isHome })}
    >
      <div className={styles.content}>
        <p className={styles.socials}>
          <a
            href="https://www.instagram.com/miriamchernick"
            target="_blank"
            rel="noreferrer"
          >
            <IgIcon />
          </a>
          <a
            href="https://twitter.com/MiriamChernick"
            target="_blank"
            rel="noreferrer"
          >
            <TwIcon />
          </a>
        </p>
        <p>
          Website by{' '}
          <a href="https://amandavacharat.com" target="_blank" rel="noreferrer">
            Amanda Vacharat
          </a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
