/* eslint jsx-a11y/click-events-have-key-events:0 */

import React, { useState, useEffect } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import classNames from 'classnames';
import { useLocation, globalHistory } from '@reach/router';
import { Link, graphql, useStaticQuery } from 'gatsby';

import NavDrawer from './NavDrawer/NavDrawer';

import * as styles from './navigation.module.scss';

export function makeNavItems(pageNodes, location) {
  return (
    <ul className={styles.navigation}>
      <li>
        <Link className={styles.navigationItem} to={`/`}>
          Home
        </Link>
      </li>
      {pageNodes.map((page) => {
        if (!page.mainNav) return null;

        return (
          <li key={page.id}>
            <Link
              to={`/${page.slug}`}
              className={styles.navigationItem}
              activeClassName={styles.active}
            >
              {page.title}
            </Link>
          </li>
        );
      })}
    </ul>
  );
}

const Navigation = () => {
  const childRef = React.useRef(null);

  const pages = useStaticQuery(graphql`
    query NavQuery {
      allContentfulPage(sort: { id: DESC }) {
        nodes {
          title
          id
          slug
          mainNav
        }
      }
    }
  `);

  const location = useLocation();
  const pageNodes = pages.allContentfulPage.nodes;

  const getCurrentPageIdx = () => {
    const currentPath = location.pathname
      .replace(/^\/+/, '')
      .replace(/\/$/, '');

    const index = pageNodes.findIndex((page) => page.slug === currentPath);

    return index > -1 ? index + 1 : 0;
  };

  const handleClick = () => {
    childRef.current.toggleDrawer();
  };

  useEffect(() => {
    //Close search on location change
    return globalHistory.listen(({ action }) => {
      if (action === 'PUSH') childRef.current.toggleDrawer(true);
    });
  }, []);

  const [pageIdx, setPageIdx] = useState(0);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    setPageIdx(getCurrentPageIdx());
  }, [location.pathname]);

  const getTransformPercent = (idx) => {
    return 6 * idx + 1.25 * idx + 2; //nav item width plus gap width plus offset of half item width
  };

  //scroll logic for styles
  const [scrolled, setScrolled] = useState(false);

  function handleScroll() {
    setScrolled(window.scrollY > 10);
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const navItems = makeNavItems(pageNodes, location);

  return (
    <div
      className={classNames(styles.navWrapper, {
        [styles.scrolled]: scrolled
      })}
    >
      <nav className={styles.container}>
        <div className={styles.navDecor}>
          <StaticImage
            alt=""
            src={'../images/motudo_Pigeonnew@2x.png'}
            width="21.5"
            height="32"
            objectFit="contain"
            objectPosition="bottom"
            layout="fixed"
            placeholder="none"
            style={{
              top: 0,
              left: `${getTransformPercent(pageIdx)}rem`,
              position: 'absolute',
              width: '21.5px'
            }}
            className={styles.pigeon}
          />
        </div>
        {navItems}
        <div
          role="button"
          aria-label="Navigation Menu Toggle"
          tabIndex={0}
          className={styles.hamburger}
          onClick={handleClick}
        />
        <NavDrawer ref={childRef} navItems={navItems} />
      </nav>
    </div>
  );
};

export default Navigation;
